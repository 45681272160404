import Axios from '@nextcloud/axios';
import LoginButton from "./LoginButton.vue";
export default {
  name: 'UpdatePassword',
  components: {
    LoginButton
  },
  props: {
    username: {
      type: String,
      required: true
    },
    resetPasswordTarget: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: false,
      loading: false,
      message: undefined,
      user: this.username,
      password: '',
      encrypted: false,
      proceed: false
    };
  },
  watch: {
    username(value) {
      this.user = value;
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      this.error = false;
      this.message = '';
      try {
        const {
          data
        } = await Axios.post(this.resetPasswordTarget, {
          password: this.password,
          proceed: this.proceed
        });
        if (data && data.status === 'success') {
          this.message = 'send-success';
          this.$emit('update:username', this.user);
          this.$emit('done');
        } else if (data && data.encryption) {
          this.encrypted = true;
        } else if (data && data.msg) {
          throw new Error(data.msg);
        } else {
          throw new Error();
        }
      } catch (e) {
        this.error = true;
        this.message = e.message ? e.message : t('core', 'Password cannot be changed. Please contact your administrator.');
      } finally {
        this.loading = false;
      }
    }
  }
};