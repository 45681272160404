var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("form", {
    ref: "loginForm",
    staticClass: "login-form",
    attrs: {
      method: "post",
      name: "login",
      action: _vm.loginActionUrl
    },
    on: {
      submit: _vm.submit
    }
  }, [_c("fieldset", {
    staticClass: "login-form__fieldset",
    attrs: {
      "data-login-form": ""
    }
  }, [_vm.apacheAuthFailed ? _c("NcNoteCard", {
    attrs: {
      title: _vm.t("core", "Server side authentication failed!"),
      type: "warning"
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.t("core", "Please contact your administrator.")) + "\n\t\t")]) : _vm._e(), _vm._v(" "), _vm.csrfCheckFailed ? _c("NcNoteCard", {
    attrs: {
      heading: _vm.t("core", "Temporary error"),
      type: "error"
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.t("core", "Please try again.")) + "\n\t\t")]) : _vm._e(), _vm._v(" "), _vm.messages.length > 0 ? _c("NcNoteCard", _vm._l(_vm.messages, function (message, index) {
    return _c("div", {
      key: index
    }, [_vm._v("\n\t\t\t\t" + _vm._s(message)), _c("br")]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.internalException ? _c("NcNoteCard", {
    class: _vm.t("core", "An internal error occurred."),
    attrs: {
      type: "warning"
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.t("core", "Please try again or contact your administrator.")) + "\n\t\t")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "hidden",
    attrs: {
      id: "message"
    }
  }, [_c("img", {
    staticClass: "float-spinner",
    attrs: {
      alt: "",
      src: _vm.loadingIcon
    }
  }), _vm._v(" "), _c("span", {
    attrs: {
      id: "messageText"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      clear: "both"
    }
  })]), _vm._v(" "), _c("h2", {
    staticClass: "login-form__headline",
    attrs: {
      "data-login-form-headline": ""
    }
  }, [_vm._v("\n\t\t\t" + _vm._s(_vm.headlineText) + "\n\t\t")]), _vm._v(" "), _c("NcTextField", {
    ref: "user",
    class: {
      shake: _vm.invalidPassword
    },
    attrs: {
      id: "user",
      label: _vm.loginText,
      name: "user",
      maxlength: 255,
      value: _vm.user,
      autocapitalize: "none",
      spellchecking: false,
      autocomplete: _vm.autoCompleteAllowed ? "username" : "off",
      required: "",
      error: _vm.userNameInputLengthIs255,
      "helper-text": _vm.userInputHelperText,
      "data-login-form-input-user": ""
    },
    on: {
      "update:value": function ($event) {
        _vm.user = $event;
      },
      change: _vm.updateUsername
    }
  }), _vm._v(" "), _c("NcPasswordField", {
    ref: "password",
    class: {
      shake: _vm.invalidPassword
    },
    attrs: {
      id: "password",
      name: "password",
      value: _vm.password,
      spellchecking: false,
      autocapitalize: "none",
      autocomplete: _vm.autoCompleteAllowed ? "current-password" : "off",
      label: _vm.t("core", "Password"),
      "helper-text": _vm.errorLabel,
      error: _vm.isError,
      "data-login-form-input-password": "",
      required: ""
    },
    on: {
      "update:value": function ($event) {
        _vm.password = $event;
      }
    }
  }), _vm._v(" "), _c("LoginButton", {
    attrs: {
      "data-login-form-submit": "",
      loading: _vm.loading
    }
  }), _vm._v(" "), _vm.redirectUrl ? _c("input", {
    attrs: {
      type: "hidden",
      name: "redirect_url"
    },
    domProps: {
      value: _vm.redirectUrl
    }
  }) : _vm._e(), _vm._v(" "), _c("input", {
    attrs: {
      type: "hidden",
      name: "timezone"
    },
    domProps: {
      value: _vm.timezone
    }
  }), _vm._v(" "), _c("input", {
    attrs: {
      type: "hidden",
      name: "timezone_offset"
    },
    domProps: {
      value: _vm.timezoneOffset
    }
  }), _vm._v(" "), _c("input", {
    attrs: {
      type: "hidden",
      name: "requesttoken"
    },
    domProps: {
      value: _vm.requestToken
    }
  }), _vm._v(" "), _vm.directLogin ? _c("input", {
    attrs: {
      type: "hidden",
      name: "direct",
      value: "1"
    }
  }) : _vm._e()], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };