// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fieldset[data-v-34bf48f7] {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
fieldset[data-v-34bf48f7] label {
  text-align: initial;
}
.update[data-v-34bf48f7] {
  margin: 0 auto;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
