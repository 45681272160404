// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-size: var(--default-font-size);
}
.login-box {
  width: 320px;
  box-sizing: border-box;
}
.login-box__link {
  display: block;
  padding: 1rem;
  font-size: var(--default-font-size);
  text-align: center;
  font-weight: normal !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.alternative-logins {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.alternative-logins .button-vue {
  box-sizing: border-box;
}
.login-passwordless .button-vue {
  margin-top: 0.5rem;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
