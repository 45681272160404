/**
 * SPDX-FileCopyrightText: 2019 Nextcloud GmbH and Nextcloud contributors
 * SPDX-License-Identifier: AGPL-3.0-or-later
 */

export const coreApps = ['', 'admin', 'log', 'core/search', 'core', '3rdparty'];
export const menuSpeed = 50;
export const PERMISSION_NONE = 0;
export const PERMISSION_CREATE = 4;
export const PERMISSION_READ = 1;
export const PERMISSION_UPDATE = 2;
export const PERMISSION_DELETE = 8;
export const PERMISSION_SHARE = 16;
export const PERMISSION_ALL = 31;
export const TAG_FAVORITE = '_$!<Favorite>!$_';